<template>
  <div style="padding: 1rem;">
    <b-overlay :show="isLoadingControlAccessData" rounded class="">
      <b-card v-if="requestData">
        <b-row class="mb-1 pr-0">
          <b-col>
            <h4>{{$t('maintenance requests')}}</h4>
            <!-- <h6>{{$t('Maintenance to Property', {house: myContracts.contractSelected.housingname})}}</h6> -->
          </b-col>
          <b-col cols="2" >
            <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property', params: {index:1, info: this.$t('maintenance requests')} }">
              <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
            </b-button>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-alert show class="p-1 text-center" :variant="badgeStatus(requestData).variant">
              {{ $t('maintenance requests') }} <strong>{{ badgeStatus(requestData).statusName }}</strong>
            </b-alert>
            <div class="border rounded p-1" v-if="!!requestData.reviewData">
              <b-row align-v="center">
                <b-col cols="12">
                  <!-- <h6>Datos de </h6> -->
                </b-col>
                <b-col>
                  <label class="font-weight-bolder">{{ badgeStatus(requestData).statusName }} {{$t('By')}}:</label><br>
                  {{ requestData.reviewData.authFullname }} <br>

                  <label class="font-weight-bolder">{{$t('Reviewed at')}}:</label><br>
                  {{ formatThisDate(requestData.reviewData.createdate) }}
                </b-col>
                <b-col md="10" class="border-left">
                  <label class="font-weight-bolder">{{$t('Reason')}}:</label><br>
                  {{ requestData.reviewData.notes }}
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- Tipo de Accesso -->
          <b-col md="">
            <div class="border rounded p-1 mb-1 request-card">
              <h5>{{$t('Request data')}}</h5>
              <b-row>
                <b-col md="">
                  <label class="font-weight-bolder"> {{ $t('Reason')}}: </label><br>
                  {{requestData.reason}}
                </b-col>
                <b-col md="12" class="mt-1">
                  <label class="font-weight-bolder"> {{ $t('description')}}: </label><br>
                    {{requestData.description}}
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col>
            <b-row>
              <!-- Fechas del Mantenimiento -->
              <b-col md="12">
                <div class="border rounded p-1 mb-1">
                  <h6>{{ $t('Maintenance Dates')}}</h6>
                  <b-row>
                    <b-col md="">
                      <label class="font-weight-bolder"> {{ $t('Initial date')}}: </label><br>
                      {{requestData.startDate}}
                    </b-col>

                    <b-col md="">
                      <label class="font-weight-bolder"> {{ $t('End date')}}: </label><br>
                      {{requestData.endDate}}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <!-- Datos del proveedor -->
              <b-col>
                <div class="border rounded p-1 provider-card">
                  <h5>{{$t('Provider data')}}</h5>
                  <b-row>
                    <b-col cols="">
                      <label class="font-weight-bolder"> {{ $t('name')}} </label><br>
                      {{requestData.providerName}}
                    </b-col>
                    <b-col md="">
                      <label class="font-weight-bolder"> {{ $t('Provider type')}} </label><br>
                      {{requestData.providerType}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="">
                      <label class="font-weight-bolder"> {{ $t('Phone')}} </label><br>
                      {{requestData.providerPhone}}
                    </b-col>
                    <b-col cols="">
                      <label class="font-weight-bolder"> {{ $t('Email')}} </label><br>
                      {{requestData.providerEmail}}
                    </b-col>
                  </b-row>

                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <!-- Files -->
          <b-col>
            <div class="border rounded p-1 mb-1" v-if="requestData.files.length > 0">
              <h5>{{$t('Docs')}}</h5>
              <div class="upload__img-wrap mt-1">
                <div v-for="file in requestData.files" :key="file.name" class="upload__img-box text-center">
                  <span>
                    <a
                      :href="imgUrl + file.file"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <feather-icon icon="FileTextIcon" size="4x"/> <br>
                      <label class="mini">{{file.name}}</label>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <!-- Images -->
          <b-col>
            <div class="border rounded p-1" v-if="requestData.images.length > 0">
              <h5>{{$t('Images')}}</h5>
              <b-row>
                <b-col md="12">
                  <div class="upload__img-wrap">
                    <div v-for="img in requestData.images" :key="img.name" class="upload__img-box">
                      <!-- <b-img :src="imgUrl + img.image" fluid alt="Responsive image" class="img-bg mt-1" ></b-img> -->
                      <a
                        :href="imgUrl + img.image"
                        target="_blank"
                        rel="noopener noreferrer"
                      > <div :style="imgGallery(img)" class='img-bg'></div>
                      </a>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>



      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { formatDate } from '@/helpers/helpers'

export default {
  async created() {
    await this.init()
    const reasons = await this.fetchControlAccessType()
    if (reasons) this.reasons = reasons || []
  },
  data() {
    return {
      isLoadingControlAccessData: false,
      requestData: null,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      reasons: []
    }
  },
  computed: {
    ...mapState('auth', ['user', 'myContracts']),
  },
  methods: {
    ...mapActions('myProperty', ['fetchMaintenanceRequests', 'fetchControlAccessType']),
    async init(){
      const idRequest = this.$route.params.id
			if(this.isANumber(idRequest)){
        this.isLoadingControlAccessData = true
        const accessRegister = await this.fetchMaintenanceRequests({idRequest})
        this.requestData = accessRegister
        this.isLoadingControlAccessData = false
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    formatThisDate(date){
      return formatDate({date, language: this.user?.languageName})
    },
		badgeStatus(request){
			const { status } = request

			let variant =  'outline-info'
			let statusName = this.$t("Pending")

			if(status === '1' ){
				variant = 'info'
				statusName = this.$t("Pending") //  1-> Pendiente
			}

			if(status === '2' ){
				variant = 'success'
				statusName = this.$t("Confirmed") // 2-> es confirmada
			}

			if( status === '3' ){
				variant = 'danger'
				statusName = this.$t("Cancelled")
			}

			if( status === '4' ){
				variant = 'warning'
				statusName = this.$t("Rejected")
			}

			return {variant, statusName}
		},
    imgGallery(img){
      return img ? `background-image: url('${this.imgUrl + img.image }');` : ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.white-background{
  background-color: white !important;
}
.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.upload__img-box {
  width: 10rem;
  padding: 0 10px;
  margin-bottom: 1rem;
}
.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}
.upload__img-close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(179, 11, 11, 0.5);
  position: absolute;
  top: 3px;
  right: 1px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}
.upload__img-close:after {
  content: '\2716';
  font-size: 14px;
  color: white;
}

.request-card{
  height: 20rem;
  max-height: 20rem;
}
.provider-card{
  height: 12rem;
  max-height: 12rem;
}
.mini{
  font-size: x-small;
}
</style>